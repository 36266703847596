body {
    margin: 0;
    padding: 0!important;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

a,
a:active,
a:visited,
a:hover {
    color: inherit;
    text-decoration: none!important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mark {
    background-color: #FFF8E1;
    padding: 8px;
    border: 1px dotted #FB8C00;
}

.markError {
    background-color: #ffebee;
    padding: 8px;
    border: 1px dotted #ef5350;
}

.markSuccess {
    padding: 8px;
    border: 1px dotted #66BB6A;
}

.iconCustom {
    vertical-align: 0
}

.iconCustomMargin {
    margin-right: 8px;
}

.iconStyle {
    margin-right:8px;
    /* color: rgba(0,0,0,0.65) */
}

.MuiIconButton-root.Mui-disabled {
    color: #ddd!important
}

.iconStyleLight {
    margin-right:8px;
    color: rgba(0,0,0,0.54)
}

.iconStyleDisabled {
    margin-right:8px;
    color: rgba(0,0,0,0.2)
}

.iconStyleError {
    margin-right:8px;
    color: #f44336
}

.p-0 {
    padding: 0!important;
}

.p-1 {
    padding: 8px!important;
}

.p-2 {
    padding: 16px!important;
}

.p-3 {
    padding: 24px!important;
}

.pb-0 {
    padding-bottom: 0!important;
}

.pb-1 {
    padding-bottom: 8px!important;
}

.pb-2 {
    padding-bottom: 16px!important;
}

.pb-3 {
    padding-bottom: 24px!important;
}

.pt-0 {
    padding-top: 0!important;
}

.pt-1 {
    padding-top: 8px!important;
}

.pt-2 {
    padding-top: 16px!important;
}

.pt-3 {
    padding-top: 24px!important;
}

.pl-0 {
    padding-left: 0!important;
}

.pl-1 {
    padding-left: 8px!important;
}

.pl-2 {
    padding-left: 16px!important;
}

.pl-3 {
    padding-left: 24px!important;
}

.pr-0 {
    padding-right: 0!important;
}

.pr-1 {
    padding-right: 8px!important;
}

.pr-2 {
    padding-right: 16px!important;
}

.pr-3 {
    padding-right: 24px!important;
}

.pr-5 {
    padding-right: 40px!important;
}

.ml-1 {
    margin-left: 8px!important;
}

.ml-2 {
    margin-left: 8px!important;
}

.mb-1 {
    margin-bottom: 8px!important;
}

.mb-2 {
    margin-bottom: 16px!important;
}

.mt-1 {
    margin-top: 8px!important;
}

.mt-2 {
    margin-top: 16px!important;
}

.mt-3 {
    margin-top: 24px!important;
}

.mt-4 {
    margin-top: 32px!important;
}

.mr-1 {
    margin-right: 8px!important;
}

.mr-2 {
    margin-right: 16px!important;
}

.fw-300 {
    font-weight: 300!important;
}

.fw-400 {
    font-weight: 400!important;
}

.fw-500 {
    font-weight: 500!important;
}

.bgLight { 
    background: #fff!important;
}

.bgGrey { 
    background: #fafafa!important;
}

.bgDark { 
    background: #777!important;
}

.btn-success {
    color: #fff!important;
    background-color: #28a745!important;
    border-color: #28a745!important;
}

.btn-success:hover {
    color: #fff!important;
    background-color: #218838!important;
    border-color: #1e7e34!important;
}

.btn-info {
    color: #fff!important;
    background-color: #007bff!important;
    border-color: #007bff!important;
}

.btn-info:hover {
    color: #fff!important;
    background-color: #0069d9!important;
    border-color: #0062cc!important;
}

.btn-warning {
    color: #212529!important;
    background-color: #ffc107!important;
    border-color: #ffc107!important;
}

.btn-warning:hover {
    color: #212529!important;
    background-color: #e0a800!important;
    border-color: #d39e00!important;
}

.MuiButton-contained.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12)!important;
    border-color: rgba(0, 0, 0, 0.12)!important
}

.tabLeft {
    background: #fff;
    border-right: 1px solid #ddd;
}

.sideLeft {
    background: #fafafa;
    border-right: 1px solid #ddd;
}

.sideRight {
    background: #fafafa;
    border-left: 1px solid #ddd;
}

.list-item {
    padding: 16px!important;
    border-bottom: 1px solid #eee!important;
    min-width: 525px!important;
    width: 100%;
    padding-right: 8px!important;
}

.list-item-borderless {
    padding: 16px!important;
    min-width: 525px!important;
    padding-right: 8px!important;
}

.menu-text {
    min-width: 500px!important;
    padding: 18px!important;
    overflow-y: hidden!important;
}

.sm-menu-text {
    min-width: 300px!important;
    padding: 18px!important;
    overflow-y: hidden!important;
}

.selected { 
    background-color: #777!important;
    color: #fff!important
}

.sm-list-item {
    padding: 16px!important;
    border-bottom: 1px solid #eee!important;
    min-width: 300px!important;
}

.sm-list-item-min {
    padding: 12px 0 12px 0!important;
}

.sm-list-item-borderless {
    padding: 16px!important;
    min-width: 300px!important;
}

.sm-list-item-borderless-white {
    background: #fff!important;
    padding: 16px!important;
    min-width: 300px!important;
}

.dd-menu {
    z-index: 99;
    text-align: left;
    margin-top: 10px;
    max-width: 575px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.react-pdf__Page {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

.w-100 {
    width: 100%!important
}

.h-100 {
    height: 100%!important
}

.xs-only {
    display: none;
}

.sm-only {
    display: none;
}

.not-sm {
    display: block;
}

.not-xs {
    display: block;
}

.mfaInput input {
    color: transparent;
    text-shadow: 0 0 0 black;
}

.gap-2 {
    height: 16px
}

.gap-3 {
    height: 24px
}

.content-light-error {
    background: #fff7f7!important;
    border: 1px solid #ffcdd2;
    border-radius: 2px
}

.content-light-error-clickable:hover {
    background: #ffeaec!important;
    cursor: pointer
}

.content-light-success {
    background: #fcfdfa!important;
    border: 1px solid #C8E6C9;
    border-radius: 2px
}

.content-light-success-clickable:hover {
    background: #f4f7ec!important;
    cursor: pointer
}

.content-light-grey {
    background: #fafafa!important;
    border: 1px solid #ddd;
    border-radius: 2px
}

.link {
    cursor: pointer
}

.MuiInputLabel-outlined {
    z-index: 0!important
}

.MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0!important;
}

.MuiExpansionPanelDetails-root > div {
    width: 100%;
}

@media (max-width:959.95px) {
    .sm-only {
        display: block;
    }
    .not-sm {
        display: none!important;
    }
    .MuiTablePagination-spacer {
        display: none;
    }
    .MuiTablePagination-toolbar.MuiToolbar-gutters {
        padding: 0;
        margin: 0;
    }
}

@media (max-width:599.95px) {
    html{
        font-size: 0.8em
    }
    .xs-only {
        display: block;
    }
    .not-xs {
        display: none!important;
    }
    .xs-max-width {
        max-width: calc(100vw - 40px);
    }
}

::-webkit-scrollbar {
    width:0.3em
}

::-webkit-scrollbar-track {
    background: #fafafa;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
}

::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
} 

/* Fix floating labels on autocomplete */
input:-webkit-autofill {
    transition-delay: 9999999s;
    -webkit-transition-delay: 9999999s;
}

/* Fix floating label on outlined textfields / multiline textfields */
.MuiInputLabel-outlined {
    z-index: 0!important
}

.MuiExpansionPanelSummary-root {
    min-height: 64px!important
}

.textPrimary {
    color: #ef3440!important;
}

.textSuccess {
    color: #2E7D32!important
}

.textInfo {
    color: #2196F3!important
}

.textWarning {
    color: #FF6F00!important
}

.textError { 
    color: #d32f2f!important
}

.textLight {
    color: #fff!important
}

.textDefault { 
    color: #000!important
}

.tileContainer {
    position: relative!important
}

.link {
    color: #000;
    text-decoration:none!important;
    cursor: pointer;
}

.link:hover {
    background-color: rgba(0,0,0,0.08)
}

.tileSquare {
    position: absolute!important;
    left: 0!important;
    top: 0!important;
    width: 75px!important;
    height: 100%!important;
    padding: 18px;
}

.tileAvatar {
    width: 100%!important;
    height: 100%!important;
    opacity: 0.85!important
}

.tileIcon  {
    color: #fff!important;
    opacity: 0.65!important;
    width: 35px!important;
    height: 35px!important;
}

.tileTitle {
    position: absolute!important;
    left: 0;
    bottom: 0;
    height: 40px!important;
    width: 100%;
    padding: 0 18px 18px 18px
}

.tileContent {
    margin-left: 75px!important;
    top: 0!important;
    width: calc(100% - 75px)!important;
    padding: 18px;
}

.tileContentFixed {
    height: 90px!important;
    margin-left: 99px!important;
}

.tileSpacer {
    padding-bottom: 3px
}

.tileContainerTitle {
    padding: 18px 18px 16px 18px;
}

.tileContainerContent {
    padding: 8px 24px 16px 24px;
    margin-top: 11px!important;
    margin-bottom: 11px!important;
}

.unread {
    background-color: #ef3340;
    color: #fff;
    padding: 16px 8px 16px 16px;
}

.Mui-error {
    margin-bottom: 7.5px!important
}
.MuiToggleButton-root.Mui-selected {
    background: rgba(0,0,0,0.05)!important;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 12px 0!important;
}

.grecaptcha-badge {
    display: none !important;
}

.Select-value-label, .Select-option {
    white-space: pre-wrap;
}
.displayFlex {
    display: flex!important;
}

.buttonRow {
    display: flex!important;
    justify-content: flex-end!important;
    margin-top: 24px!important;
}

.buttonRow > .MuiButton-root {
    margin-left: 16px!important;
    text-decoration: none!important
}

.buttonRow > a {
    margin-left: 16px!important;
    text-decoration: none!important
}

.blueLink {
    text-decoration: none!important;
    color: #ef3340!important;
    cursor: pointer;
}

.padding3 {
    padding: 24px!important
}

.paddingTop0 {
    padding-top: 0!important
}

.paddingBottom0 {
    padding-bottom: 0!important
}

.paddingRight0 {
    padding-right: 0!important
}

.paddingLeft0 {
    padding-left: 0!important
}

.vertical-timeline {
    padding: 0!important;
    margin-bottom: 16px!important;
}

.vertical-timeline-element {
    margin: 2em 0!important;
}

.vertical-timeline-element:first-child {
    margin: 1em 0 2em 0!important;
}

.vertical-timeline-element:last-child {
    margin-bottom: 0!important;
}

.vertical-timeline::before {
    width: 1px!important;
    background: rgba(0,0,0,0.65)!important;
}

.vetical-timeline-show-more--one-column::after {
    position: absolute!important;
    bottom: 0!important;
    content: '\22EE'!important;
    display: block!important;
    clear: both!important;
    margin-bottom: 30px!important;
    margin-left: 14px!important;
    height: 10px!important;
    width: 20px!important;
    border-radius: 50%!important;
    font-size: 32px!important;
    color: #fff!important;
}

.vetical-timeline-completed--one-column::after {
    position: absolute!important;
    bottom: 0!important;
    content: ''!important;
    display: table!important;
    clear: both!important;
    margin-left: 13.5px!important;
    margin-bottom: -10px!important;
    height: 10px!important;
    width: 10px!important;
    border-radius: 50%!important;
    background: rgba(0,0,0,0.6)!important;
}

.vetical-timeline-completed--two-columns::after {
    position: absolute!important;
    bottom: 0!important;
    content: ''!important;
    display: table!important;
    clear: both!important;
    left: 50%!important;
    margin-left: -6px!important;
    margin-bottom: -10px!important;
    height: 10px!important;
    width: 10px!important;
    border-radius: 50%!important;
    background: rgba(0,0,0,0.6)!important;
}

.vertical-timeline-element-icon svg { 
    margin-top: -9px!important;
    margin-left: -10px!important;
}

/* Animations */
@keyframes ripple {
    from {
        transform: scale(.8);
        opacity: 1;
    }
    to {
        transform: scale(2.4);
        opacity: 0;
    }
}

@keyframes shake {
    10%, 15% {
        transform: translate3d(-3px, 0, 0);
    }
    12.5%, 17.5% {
        transform: translate3d(3px, 0, 0);
    }
    0%, 7.5%, 20%, 100% {
        transform: translate3d(0, 0, 0);
    }
}